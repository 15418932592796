<template>
  <AssetHealthEngineStyled>
    <header>
      <ComponentHeaderMolecule :isCollapsable="true" @expanded="expanded">
        <template v-slot:icon><TalpaIcon class="icon" :scope="'Misc'" :name="'Engine'" /></template>
        <template v-slot:title>{{ $t('engine') }}</template>
        <template v-slot:components v-if="hasSignalReadPermission && hasEngineReadPermission">
          <ComponentWrapper>
            <SeverityMolecule :severities="engineSeverities" />
            <InsightsCountMolecule :value="engineIssues.length" />
            <NextMaintenanceLabelMolecule v-if="serviceAlerts.length" :serviceAlerts="serviceAlerts" :asset="asset" />
          </ComponentWrapper>
        </template>
        <template v-slot:details v-if="hasSignalReadPermission && hasEngineReadPermission">
          <AssetDetailsMolecule class="details" :details="engine" />
          <AssetDetailsMolecule v-if="ecuDetails && showECUDetails" class="border" :details="ecuDetails" />
        </template>
      </ComponentHeaderMolecule>
    </header>
    <transition name="fade">
      <main v-if="isExpanded">
        <AssetEnginePanelsOrganism
          class="content"
          :asset="asset"
          :engineManufacturer="'Deutz'"
          :hasEnginePanelPermission="hasEnginePanelPermission"
        />
      </main>
    </transition>
  </AssetHealthEngineStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon } from '@common/components'
import { getLast24HoursInterval } from '../../../../utils/time'
import localesMixin from '@/mixins/locales'
import units from '@/utils/units'
import AssetEnginePanelsOrganism from '@/components/Atomic/Organisms/Asset/Engine/AssetEnginePanelsOrganism'
import ComponentHeaderMolecule from '@/components/Atomic/Molecules/Maintenance/ComponentHeaderMolecule'
import SeverityMolecule from '@/components/Atomic/Molecules/SeverityMolecule'
import InsightsCountMolecule from '@/components/Atomic/Molecules/Maintenance/InsightsCountMolecule'
import AssetDetailsMolecule from '../AssetHealthComponentHeader/AssetDetailsMolecule'
import NextMaintenanceLabelMolecule from '@/components/Atomic/Molecules/Maintenance/NextMaintenance/NextMaintenanceLabelMolecule'

import ASSET_ISSUES_QUERY from '#/graphql/operations/maintenance/assetIssuesQuery.gql'
import LIST_ORGANIZATIONS_QUERY from '#/graphql/organizations/listMinimal.gql'
import SERVICE_ALERTS_BY_ASSET_ID from '#/graphql/operations/maintenance/nextMaintenance/serviceAlerts.gql'

const AssetHealthEngineStyled = styled('div')`
  display: grid;
  grid-gap: 0.5rem;
  .border {
    border-top: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
    padding-top: 8px;
  }
`
const ComponentWrapper = styled('div')`
  grid-area: components;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 820px) {
    flex-direction: column;
    align-items: end;
    gap: 0.5rem;
  }
  @media (max-width: 425px) {
    align-items: start;
  }
`
export default {
  mixins: [localesMixin],
  inject: ['uiSettings', 'permissions'],
  components: {
    AssetHealthEngineStyled,
    ComponentHeaderMolecule,
    SeverityMolecule,
    InsightsCountMolecule,
    ComponentWrapper,
    AssetEnginePanelsOrganism,
    TalpaIcon,
    AssetDetailsMolecule,
    NextMaintenanceLabelMolecule,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    showECUDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: true,
      assetIssues: [],
      organizations: [],
      serviceAlerts: [],
    }
  },
  computed: {
    hasSignalReadPermission() {
      return !!this.permissions?.all?.find(p => p.name === 'signal_read')
    },
    hasEngineReadPermission() {
      return !!this.permissions?.all?.find(p => p.name === 'engine_read')
    },
    hasEnginePanelPermission() {
      return !!(this.hasSignalReadPermission && this.hasEngineReadPermission)
    },
    hasDeutzExtension() {
      return !!this.permissions?.all?.find(p => p.name === 'has_deutz_extension')
    },
    ecuDetails() {
      if (!this.ecu) {
        return null
      } else {
        const ecu = this.ecu
        return [
          {
            key: this.$tc('maintenance.engine.ecu.location'),
            value: ecu.location || '-',
          },
          {
            key: this.$tc('maintenance.engine.ecu.type'),
            value: ecu.type || '-',
          },
          {
            key: this.$tc('maintenance.engine.ecu.serialNumber'),
            value: ecu.serialNumber || '-',
          },
          {
            key: this.$tc('maintenance.engine.ecu.partNumber'),
            value: ecu.partNumber || '-',
          },
          {
            key: this.$tc('maintenance.engine.ecu.manufacturerName'),
            value: ecu.manufacturerName || '-',
          },
          {
            key: this.$tc('maintenance.engine.ecu.hardwareVersion'),
            value: ecu.hardwareVersion || '-',
          },
          {
            key: this.$tc('maintenance.engine.ecu.constructionDate'),
            value: ecu.constructionDate || '-',
          },
          {
            key: this.$tc('maintenance.engine.softwareIdentificationFields'),
            value: '-', // TODO: add to query when the field is available
          },
          {
            key: this.$tc('maintenance.engine.softwareIdentification'),
            value: '-', // TODO: add to query when the field is available
          },
        ]
      }
    },
    engine() {
      return [
        {
          key: this.$tc('model'),
          value: this.asset.engines.length > 0 ? this.asset.engines[0].model.name : '-',
        },
        {
          key: this.$tc('maintenance.fuelType'),
          value: '-',
        },
        {
          key: this.$tc('serialNumber'),
          value: this.asset.engines.length > 0 ? this.asset.engines[0].serialNumber : '-',
        },
        {
          key: this.$tc('maintenance.hourMeter'),
          value: this.asset.lastPosition
            ? units(
                this.asset.lastPosition.totalMachineHours,
                '',
                '',
                2,
                false,
                true,
                false,
                this.thousandsSeperator,
                this.decimalSeperator,
                true,
              )
            : '-',
        },
        {
          key: this.$tc('maintenance.engine.make'),
          value: this.asset.engines.length > 0 ? this.asset.engines[0].make : '-',
        },
        {
          key: this.$tc('maintenance.engine.unitNumber'),
          value: this.asset.engines.length > 0 ? this.asset.engines[0].unitNumber : '-',
        },
      ]
    },

    assetLastUpdate() {
      return this.asset?.lastLog || null
    },
    last24HoursInterval() {
      return getLast24HoursInterval()
    },
    engineIssues() {
      return this.assetIssues.filter(issue => issue?.component === 'Engine #1')
    },
    manufacturerName() {
      // DEV-6717: we will only consider one engine
      const id = this.asset?.engines[0]?.ecu?.manufacturerCuid
      return this.organizations.find(f => f.id === id)
    },
    ecu() {
      return this.asset?.engines[0]?.ecu
    },
    engineSeverities() {
      const activeIssues = this.engineIssues.filter(issue => issue.isActive)
      const engineSeverities = activeIssues.reduce((acc, issue) => {
        acc.add(issue.highestSeverity)
        return acc
      }, new Set())
      if (engineSeverities.size === 0) {
        engineSeverities.add('Green')
      }
      return Array.from(engineSeverities)
    },
  },
  methods: {
    expanded(value) {
      this.isExpanded = value
    },
  },
  apollo: {
    assetIssues: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.last24HoursInterval.toISO(),
            assetIds: [this.asset.id],
            locale: this.getCustomLocale(true, false),
          },
        }
      },
      skip() {
        return !this.asset || !this.last24HoursInterval
      },
    },
    serviceAlerts: {
      query: SERVICE_ALERTS_BY_ASSET_ID,
      variables() {
        return {
          assetIds: [this.asset.id],
          locale: this.getCustomLocale(true, false),
        }
      },
      skip() {
        return !this.asset.id || !this.hasDeutzExtension
      },
    },
    organizations: {
      query: LIST_ORGANIZATIONS_QUERY,
      variables: {
        where: {
          isOEM: true,
        },
      },
    },
  },
}
</script>
