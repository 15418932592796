var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthEngineStyled', [_c('header', [_c('ComponentHeaderMolecule', {
    attrs: {
      "isCollapsable": true
    },
    on: {
      "expanded": _vm.expanded
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('TalpaIcon', {
          staticClass: "icon",
          attrs: {
            "scope": 'Misc',
            "name": 'Engine'
          }
        })];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.$t('engine')))];
      },
      proxy: true
    }, _vm.hasSignalReadPermission && _vm.hasEngineReadPermission ? {
      key: "components",
      fn: function fn() {
        return [_c('ComponentWrapper', [_c('SeverityMolecule', {
          attrs: {
            "severities": _vm.engineSeverities
          }
        }), _c('InsightsCountMolecule', {
          attrs: {
            "value": _vm.engineIssues.length
          }
        }), _vm.serviceAlerts.length ? _c('NextMaintenanceLabelMolecule', {
          attrs: {
            "serviceAlerts": _vm.serviceAlerts,
            "asset": _vm.asset
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    } : null, _vm.hasSignalReadPermission && _vm.hasEngineReadPermission ? {
      key: "details",
      fn: function fn() {
        return [_c('AssetDetailsMolecule', {
          staticClass: "details",
          attrs: {
            "details": _vm.engine
          }
        }), _vm.ecuDetails && _vm.showECUDetails ? _c('AssetDetailsMolecule', {
          staticClass: "border",
          attrs: {
            "details": _vm.ecuDetails
          }
        }) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  })], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isExpanded ? _c('main', [_c('AssetEnginePanelsOrganism', {
    staticClass: "content",
    attrs: {
      "asset": _vm.asset,
      "engineManufacturer": 'Deutz',
      "hasEnginePanelPermission": _vm.hasEnginePanelPermission
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }